<style scoped lang="less">
.top-item-row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 550px;
}
.tableContent {
  flex: 1;
  overflow: auto;
  margin: auto;
  width: 1200px;
  margin: 0 30px;
  .transition-box {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    .tableContentTitle {
      display: flex;
      align-items: center;
      font-size: 18px;
      font-weight: bold;
      margin-bottom: 10px;
      padding-left: 20px;
      .el-input {
        width: 200px;
      }
      span {
        white-space: normal;
        min-width: 240px;
      }

      .editImg {
        width: 25px;
      }
      .tableContentTitleInput {
        width: 180px;
      }
    }
  }
  .el-card {
    margin-bottom: 10px;
  }
  /deep/ .el-table__expand-icon--expanded {
    display: none;
  }
  /deep/ .el-table {
    border: 1px solid #ebeef5;
    border-radius: 5px;
    .el-table__row.statistics-warning-row {
      background: #f8faff;
    }
    tr:nth-child(4n) {
      td.el-table__cell {
        background: #f8faff;
      }
    }
    td.el-table__cell {
      border: none;
      .cell {
        font-size: 14px;
      }
    }

    tr {
      height: 28px;
    }
  }
  /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: transparent;
  }
  /deep/ .el-table__body-wrapper::-webkit-scrollbar {
    width: 12px;
    height: 12px;
  }
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: rgba(0, 0, 0, 0.1);
    -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  }
  /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb:window-inactive {
    background: #ccc;
  }
  /deep/ .el-table__body-wrapper {
    overflow-y: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
  }
  /deep/ .tableHerderStyle {
    font-weight: 600;
    font-size: 16px;
    height: 32px;
  }
  /deep/ .tableHerderStyle::before {
    content: '';
    display: inline-block;
    vertical-align: middle;
    height: 100%;
  }
}
.el-table /deep/ td {
  white-space: nowrap;
  width: fit-content;
}

.el-table /deep/ .cell {
  white-space: nowrap;
}

.button-panel {
  display: flex;
  padding: 10px;
  margin-bottom: 5px;
  background: #fff;
}
</style>

<template>
  <CmDialogBorder size="mini" :zIndex="zIndex" title="添加队列" v-show="isShow" @close="Close">
    <div class="top-item-row">
      <div class="button-panel">
        <el-button
          type="success"
          class="common-button commonBtn"
          icon="el-icon-finished"
          v-debounce="Save"
        >
          保存
        </el-button>
      </div>
      <div class="tableContent">
        <el-card>
          <div class="transition-box">
            <div class="tableContentTitle">
              <span>队列名称:</span>
              <div class="tableContentTitleInput">
                <el-input
                  clearable
                  placeholder="请输入"
                  v-model="queueName"
                  @keyup.enter.native="titleBlur"
                ></el-input>
              </div>
            </div>
            <div class="tableContentTitle">
              <span>专病数据库：</span>
              <el-select v-model="moduleCode" style="margin-right: 20px">
                <el-option
                  v-for="item in moduleList"
                  :key="item.id"
                  :label="item.moduleTitle"
                  :value="item.moduleCode"
                ></el-option>
              </el-select>
            </div>
            <div class="tableContentTitle">
              <span>项目名称：</span>
              <el-input clearable placeholder="请输入" v-model="info.project"></el-input>
            </div>
            <div class="tableContentTitle">
              <span>研究目的：</span>
              <el-input clearable placeholder="请输入" v-model="info.target"></el-input>
            </div>
            <div class="tableContentTitle">
              <span>入组条件：</span>
              <el-input clearable placeholder="请输入" v-model="info.condition"></el-input>
            </div>

            <div class="tableContentTitle">
              <span>观察终点</span>
            </div>
            <div class="tableContentTitle">
              <span>1、包含死亡&ensp;</span>
              <el-radio-group v-model="info.includingDeath" style="margin-top: 5px">
                <el-radio :label="1">是</el-radio>
                <el-radio :label="0">否</el-radio>
              </el-radio-group>
            </div>

            <div class="tableContentTitle">
              <span>2、电子表单满足以下条件</span>
              <el-select
                v-model="endpointGroup"
                slot="prepend"
                clearable
                placeholder="请选择分组"
                @change="changeConnectOptions(endpointGroup)"
              >
                <el-option
                  v-for="item in fieldGroupingList"
                  :key="item.id"
                  :label="item.title"
                  :value="item.id"
                ></el-option>
              </el-select>
              <span v-if="!moduleCode" style="color: red; font-size: 14px; margin-left: 6px">
                !请选择数据库
              </span>
            </div>
            <template v-if="endpointGroup && fieldCode !== 'noData'">
              <div class="tableContentTitle">
                <span></span>
                <el-select
                  v-model="fieldCode"
                  slot="prepend"
                  clearable
                  placeholder="请选择分组"
                  @change="changeFieldCode"
                >
                  <el-option
                    v-for="item in tree_list"
                    :key="item.id"
                    :label="item.title"
                    :value="item.id"
                  ></el-option>
                </el-select>
              </div>
              <div class="tableContentTitle" v-if="fieldCode">
                <span></span>
                <el-select
                  v-model="info.endpointField"
                  slot="prepend"
                  clearable
                  placeholder="请选择分组"
                  @change="changeEndpointField"
                >
                  <el-option
                    v-for="item in fieldChildList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                  ></el-option>
                </el-select>
              </div>
            </template>
            <template v-else>
              <div class="tableContentTitle" v-if="fieldCode">
                <span></span>
                <el-select
                  v-model="info.endpointField"
                  slot="prepend"
                  clearable
                  placeholder="请选择分组"
                  @change="changeEndpointField"
                >
                  <el-option
                    v-for="item in fieldChildList"
                    :key="item.id"
                    :label="item.title"
                    :value="item.title"
                  ></el-option>
                </el-select>
              </div>
            </template>

            <div class="tableContentTitle" v-if="info.endpointField">
              <span></span>
              <el-select
                v-model="info.endpointOperator"
                slot="prepend"
                clearable
                placeholder="请选择分组"
              >
                <el-option
                  v-for="(item, index) in relationship"
                  :key="index"
                  :label="item.label"
                  :value="item.value"
                ></el-option>
              </el-select>
            </div>

            <div class="tableContentTitle" v-if="info.endpointOperator">
              <span></span>
              <div v-if="info.endpointDataType === 'date'">
                <el-date-picker
                  v-model="info.endpointValue"
                  type="date"
                  size="mini"
                  align="center"
                  placeholder="选择日期时间"
                  value-format="yyyy-MM-dd HH:mm"
                  format="yyyy-MM-dd HH:mm"
                ></el-date-picker>
              </div>
              <div v-else>
                <el-select
                  v-if="fieldItemList.length !== 0"
                  v-model="info.endpointValue"
                  slot="prepend"
                  clearable
                  placeholder="请选择分组"
                >
                  <el-option
                    v-for="(item, index) in fieldItemList"
                    :key="index"
                    :label="item.title"
                    :value="item.title"
                  ></el-option>
                </el-select>
                <el-input
                  v-else
                  clearable
                  placeholder="请输入"
                  v-model="info.endpointValue"
                ></el-input>
              </div>
            </div>
          </div>
        </el-card>
      </div>
    </div>
  </CmDialogBorder>
</template>

<script>
import CmDialogBorder from '../../../components/CmDialogBorder.vue'
import { PopupManager } from 'element-ui/lib/utils/popup'
import { SERVER_HOST } from '@/utils/globalVariable'
import deepClone from '../../../plugins/clone'

export default {
  name: 'ResearchListDialog',
  components: {
    CmDialogBorder
  },
  props: {
    fieldGroupingList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      zIndex: PopupManager.nextZIndex(),
      isShow: false,
      tableLoading: false,
      queueName: '',
      queueId: null,
      moduleCode: '',
      endpointGroup: '', //研究目的字段
      fieldCode: '',
      info: {
        project: '', //项目名称
        target: '', //研究目的
        condition: '', //入组条件
        endpointGroup: '', //观察终点:分组
        endpointField: '', //观察终点:专病数据库字段
        endpointOperator: '', //观察终点:运算方法
        endpointDataType: '',
        endpointValue: '', //观察终点:值
        includingDeath: 0 //结局是否包含死亡
      },
      relationship: [
        {
          value: 'LIKE',
          label: '包含'
        },
        {
          value: 'NOTLIKE',
          label: '不包含'
        },
        {
          value: 'EQ',
          label: '等于'
        },
        {
          value: 'NOTEQ',
          label: '不等于'
        },
        {
          value: 'GE',
          label: '>='
        },
        {
          value: 'LE',
          label: '<='
        },
        {
          value: 'GT',
          label: '>'
        },
        {
          value: 'LT',
          label: '<'
        }
      ],

      moduleList: [],
      tree_list: [],
      fieldChildList: [],
      fieldItemList: []
    }
  },
  watch: {},
  created() {
    this.getModuleList()
  },
  methods: {
    changeEndpointField(val, type) {
      this.fieldChildList.forEach((element) => {
        if (val === element.title) {
          this.info.endpointDataType = element.data.datatype
          if (element.data.selectItems) {
            this.fieldItemList = element.data.selectItems
          } else {
            this.fieldItemList = []
          }
        }
      })
      if (type !== 'init') {
        this.info.endpointValue = ''
      }
    },
    changeFieldCode(val) {
      this.tree_list.forEach((element) => {
        if (val === element.id) {
          this.fieldChildList = element.child
        }
      })
    },
    async changeConnectOptions(val) {
      if (val) {
        if (!this.moduleCode) {
          this.endpointGroup = ''
          return this.$message.warning('请先选择数据库')
        }
        this.fieldGroupingList.forEach((element) => {
          if (val === element.id) {
            this.info.endpointGroup = element.title
          }
        })
        const { data: res } = await this.$http.get(
          SERVER_HOST + `/v1/webconsole/search/tree/${this.moduleCode}/${val}`
        )
        this.get_tree_data(res.data)
      }
    },
    get_tree_data(val) {
      if (!val.child[0].child) {
        this.fieldCode = 'noData'
        this.fieldChildList = this.turnToTreeOfOneRootPlus(val.child)
      } else {
        this.tree_list = this.turnToTreeOfOneRootPlus(val.child)
      }
    },
    // 建树
    turnToTreeOfOneRootPlus(arr) {
      if (arr) {
        for (let obj of arr) {
          obj['title'] = obj['data'].title
          obj['datatype'] = obj['data'].datatype
          if (obj['child']) {
            this.turnToTreeOfOneRootPlus(obj['child'])
          }
        }
        return arr
      }
    },
    getModuleList() {
      this.$api.get(`/v1/webconsole/study/mymodulelist?moduleType=1`).then((res) => {
        if (res.data && res.data.data.length > 0) {
          this.moduleList = res.data.data
        }
      })
    },
    Show(bloodVessel) {
      this.queueId = ''
      this.moduleCode = ''
      if (bloodVessel) {
        this.queueId = deepClone(bloodVessel.id)
        this.moduleCode = deepClone(bloodVessel.moduleCode)
        this.queueName = deepClone(bloodVessel.queueName)
        this.info.project = deepClone(bloodVessel.project)
        this.info.target = deepClone(bloodVessel.target)
        this.info.condition = deepClone(bloodVessel.condition)
        this.info.endpointGroup = deepClone(bloodVessel.endpointGroup)
        if (this.info.endpointGroup) {
          this.fieldGroupingList.forEach((ele) => {
            if (this.info.endpointGroup === ele.title) {
              this.endpointGroup = ele.id
            }
          })
          this.changeConnectOptions(this.endpointGroup)
          setTimeout(() => {
            this.info.endpointField = deepClone(bloodVessel.endpointField)
            if (this.fieldCode !== 'noData') {
              this.tree_list.forEach((element) => {
                element.child.forEach((ele) => {
                  if (this.info.endpointField === ele.title) {
                    this.fieldCode = element.id
                    this.fieldChildList = element.child
                  }
                })
              })
            }

            this.changeEndpointField(this.info.endpointField, 'init')
            this.info.endpointOperator = deepClone(bloodVessel.endpointOperator)
            this.info.endpointValue = deepClone(bloodVessel.endpointValue)
          }, 1800)
        }

        this.info.includingDeath = deepClone(bloodVessel.includingDeath)
      }
      this.isShow = true
    },
    saveScientificList() {
      if (this.queueName) {
        let data = {
          queueName: this.queueName,
          moduleCode: this.moduleCode,
          project: this.info.project || null,
          target: this.info.target || null,
          condition: this.info.condition || null,
          endpointGroup: this.info.endpointGroup || null,
          endpointField: this.info.endpointField || null,
          endpointOperator: this.info.endpointOperator || null,
          endpointValue: this.info.endpointValue || null,
          endpointDataType: this.info.endpointDataType || null,
          includingDeath: this.info.includingDeath
        }
        if (this.queueId === '') {
          this.$api.post(`/v1/webconsole/scientificResearchQueue/new`, data).then(
            (res) => {
              if (res.data && res.data.data) {
                this.queueId = res.data.data.id
                this.queueName = res.data.data.queueName
                this.$message({
                  message: '添加科研队列成功',
                  type: 'success'
                })
                this.$emit('save')
                this.Close()
              }
            },
            () => {
              this.$emit('save')
            }
          )
        } else {
          this.$api
            .post(
              `/v1/webconsole/scientificResearchQueue/update/${this.queueId}?queueName=${this.queueName}&moduleCode=${this.moduleCode}`,
              data
            )
            .then(
              (res) => {
                if (res.data && res.data.data) {
                  this.$message({
                    message: '修改科研队列成功',
                    type: 'success'
                  })
                  this.$emit('save')
                  this.Close()
                }
              },
              () => {
                this.$emit('save')
              }
            )
        }
      }
    },
    Save() {
      this.saveScientificList()
    },
    Close() {
      this.queueId = ''
      this.queueName = ''
      this.endpointGroup = ''
      this.fieldCode = ''
      this.tree_list = []
      this.fieldChildList = []
      this.fieldItemList = []
      this.info = this.$options.data().info
      this.isShow = false
    }
  }
}
</script>
