import { render, staticRenderFns } from "./AddPatients.vue?vue&type=template&id=119a9a41&scoped=true"
import script from "./AddPatients.vue?vue&type=script&lang=js"
export * from "./AddPatients.vue?vue&type=script&lang=js"
import style0 from "./AddPatients.vue?vue&type=style&index=0&id=119a9a41&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "119a9a41",
  null
  
)

export default component.exports