<style lang="less" scoped>
.el-input .el-select {
  width: 150px;
}
.input-with-select .el-input-group__prepend {
  background-color: #fff;
}
/deep/ .input-with-select {
  .el-input-group__prepend {
    .el-input__inner {
      color: #000;
      text-align: center;
    }
    .el-input__inner::placeholder {
      color: #000;
      text-align: center;
    }
    /* 谷歌 */
    .el-input__inner::-webkit-input-placeholder {
      color: #000;
      text-align: center;
    }
    /* 火狐 */
    .el-input__inner:-moz-placeholder {
      color: #000;
      text-align: center;
    }
    /*ie*/
    .el-input__inner:-ms-input-placeholder {
      color: #000;
      text-align: center;
    }
  }
}

.researchQueue-index-box {
  display: flex;
  height: calc(100vh - 60px);
  .researchQueue-container {
    display: flex;
    flex-direction: column;
    .header {
      padding: 0 10px;
      height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 2px 0 #ccc;
      background-color: #f2f2f2;
      .queryInput {
        display: flex;
        align-items: center;
        .queryInputLabel {
          margin: 0 20px;
          width: 100px;
          font-size: 18px;
          min-height: 28px;
        }
        .queryInputText {
          margin: 0 10px;
          width: 400px;
        }
        .queryInputBtn {
          margin: 0 10px;
          min-height: 30px;
          .queryInputBtnBox {
            display: flex;
            align-items: center;
            font-size: 16px;
          }
          span {
            margin: 0 10px;
          }
        }
      }
    }
    .content::-webkit-scrollbar {
      height: 12px;
      width: 12px;
    }

    .content::-webkit-scrollbar-track {
      border-radius: 10px;
    }
    .content::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.2);
    }
    .content::-webkit-scrollbar-thumb:hover {
      background: rgba(157, 165, 183, 0.7);
    }
    .content::-webkit-scrollbar-thumb:window-inactive {
      background: #ccc;
    }
    .content {
      flex: 1;
      overflow: auto;
      padding-top: 10px;
      .module-code-content {
        margin: 0 35px;
        .module-queueName {
          font-weight: bold;
          font-size: 18px;
          margin-bottom: 10px;
        }
        .tag-list {
          display: flex;
          align-items: center;
          margin-bottom: 5px;
          font-size: 16px;
          .tag-item {
            cursor: pointer;
            margin-right: 8px;
            .tag-item-title {
              color: #124eff;
              text-decoration: underline;
              margin-right: 4px;
            }
          }
        }
      }
      .searchContent {
        margin: 10px 35px;
        .transition-box {
          min-height: 80px;
          .transition-box-content {
            margin-bottom: 40px;
          }
          .queryBox {
            margin-bottom: 20px;
            display: flex;
            align-items: center;
          }
        }
      }
      .tableContent {
        width: 100%;

        .patientNameContent {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .patientIsDead {
          width: 20px;
          height: 20px;
          line-height: 15px;
          border-radius: 10px;
          border: 2px solid red;
          color: red;
        }
        /deep/ .el-table {
          border-left: none;
          border-right: none;
          border-bottom: none;
          .el-table__row {
            height: 56px;
            line-height: 56px;
            font-size: 14px;
          }
          .el-table__row.statistics-warning-row {
            background: #f8faff;
          }
          tr:nth-child(4n) {
            td.el-table__cell {
              background: #f8faff;
            }
          }
          td.el-table__cell {
            .cell {
              font-size: 14px;
            }
          }
        }
        /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
          background-color: transparent;
        }
        /deep/ .el-table__body-wrapper::-webkit-scrollbar {
          width: 12px;
          height: 12px;
        }
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          border-radius: 10px;
        }
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
          border-radius: 10px;
          background: rgba(0, 0, 0, 0.1);
          -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
          box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        }
        /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb:window-inactive {
          background: #ccc;
        }
        /deep/ .tableHerderStyle {
          font-size: 14px;
          height: 40px;
          line-height: 40px;
        }
        .tableAnimationHeight {
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .el-pagination {
          text-align: center;
        }
        .topBtn {
          .topBtnItem {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 0 12px;
            cursor: pointer;
            span {
              font-size: 14px;
            }
            .searchImg {
              width: 20px;
              height: 20px;
            }
            .listCImg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
  .empty-module {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .empty-label {
      font-size: 22px;
      font-weight: bold;
    }
  }
  .content-sidebar {
    width: 15%;
    box-shadow: 0 0 0.375rem rgba(0, 0, 0, 0.1);
    background-color: #fff;
    color: #000;
    .sidebar-header {
      background-color: #f2f2f2;
      margin: 0 auto;
      min-height: 45px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      box-shadow: 0 2px 2px #ccc;
      border-right: 1px solid #e6e6e6;
      .sidebar-header-label {
        margin-left: 20px;
        font-size: 18px;
        min-height: 28px;
      }
      .sidebar-header-Btn {
        padding: 0 10px;
        .el-button {
          font-size: 14px;
        }
      }
    }
    .topBox {
      height: calc(100vh - 115px);
      overflow-y: auto;
      scrollbar-width: none;
      -ms-overflow-style: none;
      border-right: 1px solid #e6e6e6;
      .queueBox {
        background: #fff;
        color: #000;
        border-bottom: 1px solid #eee;
      }
      .queueItem {
        display: flex;
        align-items: center;
        min-height: 66px;
        padding: 0 10px;
        .queueItemIcon {
          display: flex;
          align-items: center;
          margin-right: 6px;
          cursor: pointer;
          img {
            width: 30px;
            height: 30px;
          }
          .queueItemIconElse {
            width: 30px;
            height: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          .el-icon-setting {
            font-size: 28px;
          }
        }
        .queueItemContent {
          display: flex;
          align-items: center;
          justify-content: space-between;
          width: 100%;
        }
        .queueItemTitle {
          cursor: pointer;
        }
        .queueItemImg {
          display: flex;
          .cardButton {
            display: none;
            position: relative;
            top: 2px;
            right: 0;
            .buttonContent {
              width: 18px;
              height: 18px;
              line-height: 18px;
              padding: 0;
            }
          }
          .queueItemImgBtn {
            padding-left: 8px;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            span {
              font-size: 14px;
            }
            .el-icon-close {
              font-size: 20px;
              color: red;
            }
            .editImg {
              width: 20px;
              height: 20px;
            }
            .analysisImg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
      /deep/ .topBox::-webkit-scrollbar {
        width: 12px;
      }
      /deep/ .topBox::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
      }
      /deep/ .topBox::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background: rgba(0, 0, 0, 0.1);
        -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
        box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      }
      /deep/ .topBox::-webkit-scrollbar-thumb:window-inactive {
        background: #ccc;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    .researchQueue-container {
      .header {
        .queryInputLabel {
          margin: 0 10px;
        }
        .queryInput {
          .queryInputBtn {
            margin: 0;
            margin-left: 5px;
          }
        }
      }
    }
    .content-sidebar {
      width: 20%;
      .sidebar-header {
        flex-wrap: wrap;
        .sidebar-header-label {
          width: 100px;
        }
      }
    }
  }
}
</style>

<template>
  <div class="researchQueue-index">
    <IndexHeader />
    <div class="researchQueue-index-box">
      <div class="content-sidebar">
        <div class="sidebar-header">
          <div class="sidebar-header-label">队列研究</div>
          <div class="sidebar-header-Btn">
            <el-button
              size="mini"
              type="primary"
              class="commonBtn"
              icon="el-icon-circle-plus-outline"
              @click="addClick"
            >
              添加
            </el-button>
          </div>
        </div>
        <div class="topBox">
          <div
            class="queueBox"
            v-for="item in allScientificList"
            :key="item.id"
            :style="queueId === item.id ? 'background: #ECF5FF' : ''"
          >
            <div class="queueItem">
              <div class="queueItemIcon" @click="handleScientificList(item)">
                <img
                  v-if="item.moduleCode === 'AorticInfo'"
                  src="@/assets/img/followNav/AorticInfo.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'Cirrhosis'"
                  src="@/assets/img/followNav/Cirrhosis.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'PeripheralVessels'"
                  src="@/assets/img/followNav/PeripheralVessels.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'AorticDissection'"
                  src="@/assets/img/followNav/AorticDissection.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'NerveDavf'"
                  src="@/assets/img/followNav/NerveDavf.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'Electrophysiology'"
                  src="@/assets/img/followNav/Electrophysiology.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'CoronaryInfo'"
                  src="@/assets/img/followNav/CoronaryInfo.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'Pacemaker'"
                  src="@/assets/img/followNav/Pacemaker.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'LiverCancer'"
                  src="@/assets/img/followNav/LiverCancer.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'AorticAneurysm'"
                  src="@/assets/img/followNav/AorticAneurysm.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'NerveCcf'"
                  src="@/assets/img/followNav/NerveCcf.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'NerveAneurysm'"
                  src="@/assets/img/followNav/AorticAneurysm.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'NerveArteryStenosis'"
                  src="@/assets/img/followNav/AorticDissection.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'NerveAvm'"
                  src="@/assets/img/followNav/AorticDissection.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'NerveCe'"
                  src="@/assets/img/followNav/NerveCe.png"
                  alt=""
                />
                <img
                  v-else-if="item.moduleCode === 'Mitral'"
                  src="@/assets/img/followNav/Mitral.png"
                  alt=""
                />
                <div v-else class="queueItemIconElse">
                  <i class="el-icon-setting"></i>
                </div>
              </div>
              <div class="queueItemContent">
                <div class="queueItemTitle" @click="handleScientificList(item)">
                  <div style="font-weight: bold">
                    {{ item.queueName ? item.queueName : '' }}
                    {{ item.operationNumber ? '(' + item.operationNumber + ')' : '' }}
                  </div>
                  <div v-if="item.project">{{ item.project }}</div>
                </div>
                <div class="queueItemImg">
                  <div class="queueItemImgBtn" @click="editClick(item)">
                    <img :src="editClickImg" class="editImg" />
                    <span>编辑</span>
                  </div>
                  <div class="queueItemImgBtn" @click="closeInfo(item.id)">
                    <i class="el-icon-close"></i>
                    <span>删除</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-show="!addPatientsShow" style="width: 85%">
        <div class="researchQueue-container" v-if="this.allScientificList.length !== 0">
          <div class="header">
            <div class="queryInput">
              <div class="queryInputLabel">
                <span>关键字搜索</span>
              </div>
              <div class="queryInputText">
                <el-input
                  size="small"
                  type="string"
                  clearable
                  placeholder="请输入关键字"
                  v-model="queryKeyword"
                  @change="changeQueryKeyword"
                  @keyup.enter.native="query_resultPost"
                ></el-input>
              </div>
              <div class="queryInputBtn">
                <el-button size="mini" type="primary" class="commonBtn" @click="queryRetrieval">
                  <div class="queryInputBtnBox">
                    <i class="el-icon-finished"></i>
                    <span>查询</span>
                  </div>
                </el-button>
              </div>
              <div class="queryInputBtn">
                <el-button
                  size="mini"
                  type="primary"
                  class="commonBtn"
                  @click="getPatientList('clear')"
                >
                  <div class="queryInputBtnBox">
                    <i class="el-icon-refresh-left"></i>
                    <span>清空</span>
                  </div>
                </el-button>
                <el-button size="mini" type="primary" class="commonBtn" @click="addPatientsList">
                  <div class="queryInputBtnBox">
                    <i class="el-icon-plus"></i>
                    <span>队列管理</span>
                  </div>
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  class="commonBtn"
                  v-if="multipleSelection.length >= 1"
                  @click="deleteOperation"
                >
                  <div class="queryInputBtnBox">
                    <i class="el-icon-s-promotion"></i>
                    <span>批量删除</span>
                  </div>
                </el-button>
              </div>
            </div>
            <div class="queryInput">
              <div class="queryInputBtn">
                <el-button size="mini" type="primary" class="commonBtn" @click="addSearch">
                  <div class="queryInputBtnBox">
                    <i class="el-icon-upload"></i>
                    <span>保存查询条件和显示字段</span>
                  </div>
                </el-button>
                <el-button
                  size="mini"
                  type="primary"
                  class="commonBtn"
                  :disabled="dialogFieldsList.length === 0"
                  @click="addFieldsList"
                >
                  <div class="queryInputBtnBox">
                    <i v-if="dialogFieldsList.length === 0" class="el-icon-loading"></i>
                    <i v-else class="el-icon-plus"></i>
                    <span v-if="dialogFieldsList.length === 0">加载中...</span>
                    <span v-else>设置显示字段</span>
                  </div>
                </el-button>
              </div>
            </div>
          </div>
          <div class="content">
            <div class="module-code-content">
              <div class="tag-list" v-show="bigqueryList.length !== 0">
                <div class="tag-item" v-for="(item, index) in bigqueryList" :key="index">
                  <span class="tag-item-title" @click="handleBigquery(item)">{{ item.title }}</span>
                  <span style="color: red" @click="closeBigquery(item)">
                    <i class="el-icon-close"></i>
                  </span>
                </div>
              </div>
            </div>
            <div class="module-code-content">
              <div class="tag-list" v-show="patientsTagList.length !== 0">
                分组：
                <el-radio-group size="mini" v-model="infoTitle">
                  <el-radio
                    v-for="(item, index) in patientsTagList"
                    :key="index"
                    :label="item"
                    @click.native.prevent="handlePatientsTag(item)"
                    border
                  >
                    {{ item }}
                  </el-radio>
                </el-radio-group>
              </div>
            </div>
            <div class="searchContent">
              <div class="transition-box">
                <div class="transition-box-content" v-for="(item, index) in list" :key="index">
                  <!-- 连接关系 -->
                  <div class="queryBox">
                    <span style="margin-right: 20px">连接关系</span>
                    <el-select
                      v-model="item.connect_options.value"
                      style="margin-right: 20px"
                      clearable
                      v-if="index >= 1"
                    >
                      <el-option
                        v-for="item1 in item.connect_options"
                        :key="item1.value"
                        :label="item1.label"
                        :value="item1.value"
                      ></el-option>
                    </el-select>
                    <el-input
                      v-model="item.select_item"
                      class="input-with-select"
                      style="width: 40%"
                      clearable
                      @clear="clearOptions(item)"
                      @focus="getOptions(item)"
                      :disabled="moduleCode === ''"
                    >
                      <el-select
                        v-model="item.Retrieval_field_grouping.nodeTitle"
                        slot="prepend"
                        placeholder="请选择分组"
                        @change="
                          changeConnectOptions(item.Retrieval_field_grouping.nodeTitle, index)
                        "
                        :disabled="moduleCode === ''"
                      >
                        <el-option
                          v-for="item1 in Retrieval_field_grouping"
                          :key="item1.id"
                          :label="item1.title"
                          :value="item1.id"
                        ></el-option>
                      </el-select>
                    </el-input>
                  </div>

                  <!-- 运算关系 -->
                  <div style="margin-bottom: 20px; display: flex; align-items: center">
                    <span style="margin-right: 20px">运算关系</span>
                    <el-select
                      v-model="item.relationship.value"
                      style="margin-right: 20px"
                      clearable
                      :disabled="moduleCode === ''"
                    >
                      <el-option
                        v-for="item1 in item.relationship"
                        :key="item1.value"
                        :label="item1.label"
                        :value="item1.value"
                      ></el-option>
                    </el-select>
                    <!-- 根据所选树节点类型去调用不同的组件 -->
                    <!-- 下拉框-combodata-->
                    <el-select
                      v-model="item.field_values.comboValue"
                      style="margin-left: 20px; width: 20%"
                      clearable
                      v-if="item.nodedata.selectItems"
                    >
                      <el-option
                        v-for="(item1, idx) in item.field_values.itemsList"
                        :key="item1.id || idx"
                        :label="item1.title"
                        :value="item1.title"
                      ></el-option>
                    </el-select>
                    <!-- dateSelect -->
                    <el-date-picker
                      v-model="item.field_values.comboValue"
                      type="datetime"
                      align="center"
                      placeholder="选择日期"
                      v-else-if="item.nodedata.datatype === 'date'"
                      value-format="yyyy-MM-dd HH:mm:ss"
                      format="yyyy-MM-dd HH:mm"
                    ></el-date-picker>
                    <el-input
                      clearable
                      v-model="item.field_values.comboValue"
                      v-else-if="item.nodedata.datatype === 'string'"
                      style="margin-left: 20px; width: 20%"
                    ></el-input>
                    <el-input
                      clearable
                      type="number"
                      v-else-if="item.nodedata.datatype === 'number'"
                      v-model="item.field_values.comboValue"
                      style="margin-left: 20px; width: 20%"
                    ></el-input>

                    <el-button
                      v-if="index === list.length - 1 || list.length === 1"
                      size="mini"
                      type="primary"
                      class="commonBtn"
                      style="margin-left: 20px"
                      @click="addListItem"
                    >
                      <i class="el-icon-plus" />
                      新增
                    </el-button>
                    <el-button
                      @click="delListItem"
                      size="mini"
                      type="danger"
                      class="commonBtn"
                      style="margin-left: 30px"
                      v-if="index > 0"
                    >
                      删除
                    </el-button>
                  </div>

                  <!-- 检索字段搜查的树 -->
                  <el-dialog
                    title="查询条件选择"
                    :visible.sync="item.Query_conditions_Visible"
                    width="50%"
                  >
                    <el-tree
                      :data="tree_list"
                      :props="item.defaultProps"
                      @current-change="
                        (val) => {
                          select_items(val, item)
                        }
                      "
                    ></el-tree>
                  </el-dialog>
                </div>
              </div>
            </div>
            <div class="tableContent">
              <el-table
                v-loading="tableLoading"
                :data="tableData"
                style="width: 100%"
                border
                :row-class-name="onTableRowClassName"
                :height="tableHeight"
                :header-cell-class-name="headerStyle"
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="55"></el-table-column>
                <el-table-column label="结局" prop="IS_HAPPEN" mim-width="120" align="center">
                  <template slot-scope="scope">
                    <el-radio-group
                      v-if="scope.row.isSelected === true"
                      v-model="scope.row.IS_HAPPEN"
                      @change="changeISHAPPEN(scope.row)"
                    >
                      <el-radio :label="1">是</el-radio>
                      <el-radio :label="0">否</el-radio>
                    </el-radio-group>
                    <p style="cursor: pointer" @click="cellClick(scope.row)" v-else>
                      {{ scope.row.IS_HAPPEN === 1 ? '是' : '否' }}
                    </p>
                  </template>
                </el-table-column>
                <el-table-column
                  :label="item.title"
                  :property="item.code"
                  v-for="item in defaultFieldsList"
                  :key="item.id"
                  align="center"
                  min-width="120"
                >
                  <template slot-scope="scope">
                    <span>{{ scope.row[scope.column.property] }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="" width="80px" align="center" fixed="right">
                  <template slot-scope="scope">
                    <div class="patientNameContent">
                      <el-button @click="searchClick(scope.row)" type="text" style="padding: 0">
                        详情
                      </el-button>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <el-pagination
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="searchInfo.pagenum"
                :page-sizes="[15, 30, 50]"
                :page-size="searchInfo.pagesize"
                layout="total, sizes, prev, pager, next, jumper"
                :total="searchInfo.total"
              ></el-pagination>
            </div>
          </div>
        </div>
        <div v-else class="empty-module">
          <div class="empty-label">暂无数据</div>
          <div class="empty-label">请添加队列</div>
        </div>
      </div>
      <div v-show="addPatientsShow" style="width: 85%">
        <AddPatients
          ref="addPatients"
          :moduleCode="moduleCode"
          :dialogDefaultFieldsList="dialogDefaultFieldsList"
          @save="_dialogSavePatientsList"
        ></AddPatients>
      </div>
    </div>
    <ResearchListDialog
      ref="researchListDialog"
      :fieldGroupingList="Retrieval_field_grouping"
      @save="_dialogSave"
    ></ResearchListDialog>
    <SaveFieldsDialog ref="saveFieldsDialog" @save="saveSearch"></SaveFieldsDialog>
    <AddFieldsListDialog
      ref="addFieldsListDialog"
      @save="_dialogSaveFieldsList"
    ></AddFieldsListDialog>
    <!-- <AddPatientsDialog
      ref="addPatientsDialog"
      :moduleCode="moduleCode"
      :dialogDefaultFieldsList="dialogDefaultFieldsList"
      @save="_dialogSavePatientsList"
    ></AddPatientsDialog> -->
  </div>
</template>
<script>
import IndexHeader from '../../components/IndexHeader.vue'
import ResearchListDialog from './components/ResearchListDialog.vue'
import AddFieldsListDialog from './components/AddFieldsListDialog.vue'
// import AddPatientsDialog from './components/AddPatientsDialog'
import AddPatients from './components/AddPatients'
import SaveFieldsDialog from './components/SaveFieldsDialog'
import { SERVER_HOST } from '../../utils/globalVariable'
export default {
  name: 'ResearchQueue',
  components: {
    IndexHeader,
    ResearchListDialog,
    AddFieldsListDialog,
    SaveFieldsDialog,
    // AddPatientsDialog,
    AddPatients
  },
  data() {
    return {
      editClickImg: require('@/assets/img/patientCenter/edit.png'),
      queryKeyword: '',
      changeValue: '',
      tableLoading: false,
      addPatientsShow: false,
      tableData: [],
      allScientificList: [],
      tableHeight: window.innerHeight - 310, //表格动态高度
      screenHeight: window.innerHeight, //内容区域高度
      searchInfo: {
        pagenum: 1,
        pagesize: 50,
        total: 0
      },
      // 字段分组下拉框
      Retrieval_field_grouping: [],
      // 选中的字段
      query_condition: {
        nodes: []
      },
      list: [
        {
          connect_options: [
            {
              value: 'and',
              label: 'and'
            },
            {
              value: 'or',
              label: 'or'
            }
          ],
          select_item: '',
          Retrieval_field_grouping: {
            nodeTitle: ''
          },
          defaultProps: {
            children: 'child',
            label: 'title'
          },
          Query_conditions_Visible: false,
          relationship: [
            {
              value: 'LIKE',
              label: '包含'
            },
            {
              value: 'NOTLIKE',
              label: '不包含'
            },
            {
              value: 'EQ',
              label: '等于'
            },
            {
              value: 'NOTEQ',
              label: '不等于'
            },
            {
              value: 'GE',
              label: '>='
            },
            {
              value: 'LE',
              label: '<='
            },
            {
              value: 'GT',
              label: '>'
            },
            {
              value: 'LT',
              label: '<'
            }
          ],
          field_values: {},
          nodedata: {}
        }
      ],
      //暂存的树
      tree_list: [],
      defaultFieldsList: [],
      dialogDefaultFieldsList: [],
      selectFieldsList: [],
      dialogFieldsList: [],
      allFieldsList: [],
      bigqueryList: [],
      patientsTagList: [],
      multipleSelection: [],
      bigqueryInfo: null,
      moduleCode: '',
      moduleTitle: '',
      infoTitle: '',
      menusIds: []
    }
  },
  created() {
    this.getScientificResearchQueue()
  },
  mounted() {
    window.onresize = () => {
      return () => {
        // window.innerHeight:浏览器的可用高度
        window.screenHeight = window.innerHeight
        this.screenHeight = window.screenHeight
      }
    }
  },
  watch: {
    // 监听screenHeight从而改变table的高度
    screenHeight(val) {
      this.screenHeight = val
      this.tableHeight = this.screenHeight - 285
    }
  },
  methods: {
    changeISHAPPEN(val) {
      val.isSelected = !val.isSelected
      let url = `/v1/webconsole/scientificResearchQueue/operation/queue/update/${val.OPERATIONID}/${this.queueId}`
      let formData = new FormData()
      formData.append('isHappen', val.IS_HAPPEN)

      this.$api.post(url, formData).then(() => {
        this.$message({
          message: '修改成功',
          type: 'success',
          duration: 1000
        })
        this.query_resultPost()
      })
    },
    cellClick(row) {
      row.isSelected = !row.isSelected
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    getDialogDefaultFields() {
      let url = `/v1/webconsole/search/fields/${this.moduleCode}`
      this.$api.post(url).then((res) => {
        if (res.data && res.data.data.length > 0) {
          this.dialogDefaultFieldsList = res.data.data
          this.dialogDefaultFieldsList.forEach((element) => {
            element.code = this.processNextFollowDate(element.field)
          })
        }
      })
    },
    getDefaultFields(id) {
      let url = ''
      if (this.queueId) {
        url = `/v1/webconsole/scientificResearchQueue/fields/${this.queueId}`
      }
      if (id) {
        url = `/v1/webconsole/scientificResearchQueue/fields/${this.queueId}?filterId=${id}`
      }
      this.$api.post(url).then((res) => {
        if (res.data && res.data.data.length > 0) {
          this.defaultFieldsList = res.data.data
          let arr = []
          this.defaultFieldsList.forEach((element) => {
            element.code = this.processNextFollowDate(element.field)
            arr.push(element.id)
          })
          this.menusIds = arr
        }
      })
    },
    getBigquery() {
      this.$api
        .post(`/v1/webconsole/scientificResearchQueue/filters/${this.queueId}`)
        .then((res) => {
          if (res.data && res.data.data) {
            this.bigqueryList = res.data.data
          }
        })
    },
    closeBigquery(val) {
      this.$api.post(`/v1/webconsole/search/filter/delete/${val.id}`).then(() => {
        this.getBigquery()
        this.query_resultPost()
      })
    },
    handlePatientsTag(title) {
      if (this.infoTitle === title) {
        this.infoTitle = ''
      } else {
        this.infoTitle = title
      }
      this.tableLoading = true
      this.query_resultPost()
    },
    handleBigquery(val) {
      this.tableLoading = true
      this.bigqueryInfo = {
        and: val.and,
        or: val.or,
        fields: val.fields
      }
      this.searchInfo.pagenum = 1

      this.list = [
        {
          connect_options: [
            {
              value: 'and',
              label: 'and'
            },
            {
              value: 'or',
              label: 'or'
            }
          ],
          select_item: '',
          Retrieval_field_grouping: {
            nodeTitle: ''
          },
          defaultProps: {
            children: 'child',
            label: 'title'
          },
          Query_conditions_Visible: false,
          relationship: [
            {
              value: 'LIKE',
              label: '包含'
            },
            {
              value: 'NOTLIKE',
              label: '不包含'
            },
            {
              value: 'EQ',
              label: '等于'
            },
            {
              value: 'NOTEQ',
              label: '不等于'
            },
            {
              value: 'GE',
              label: '>='
            },
            {
              value: 'LE',
              label: '<='
            },
            {
              value: 'GT',
              label: '>'
            },
            {
              value: 'LT',
              label: '<'
            }
          ],
          field_values: {},
          nodedata: {}
        }
      ]
      this.getDefaultFields(val.id)
      this.$api
        .post(
          `/v1/webconsole/scientificResearchQueue/operation/fields/${this.queueId}?current=${this.searchInfo.pagenum}&size=${this.searchInfo.pagesize}&tag=${this.infoTitle}`,
          this.bigqueryInfo
        )
        .then(
          (res) => {
            if (res.data.status !== 200)
              this.$message.error('未查询到相关信息'), (this.tableData = [])
            if (res.data && res.data.data) {
              this.tableData = res.data.data.records.map((item) => {
                return { ...item, isSelected: false }
              })
              this.searchInfo.total = res.data.data.total
            } else {
              this.tableData = []
              this.searchInfo.total = 0
            }
          },
          (err) => {
            console.log(err)
          }
        )
        .finally(() => {
          this.tableLoading = false
        })
    },
    processNextFollowDate(dateValue) {
      // 确保 dateValue 是字符串
      const dateStr = dateValue.toString()

      // 检查是否包含小数点
      if (dateStr.includes('.')) {
        // 有小数点，取小数点后的部分
        const decimalPart = dateStr.split('.')[1]
        return decimalPart
      } else {
        // 没有小数点，返回完整的值
        return dateStr
      }
    },
    calculateAgeFromDays(days) {
      if (days) {
        const currentDate = new Date()
        const currentYear = currentDate.getFullYear()

        const birthDate = new Date(currentDate.getTime() - days * 24 * 60 * 60 * 1000)
        const birthYear = birthDate.getFullYear()

        let age = currentYear - birthYear

        // 检查生日是否已经过了，如果还没过，则减去一年
        if (currentDate < new Date(birthDate.setFullYear(currentYear))) {
          age--
        }

        if (age < 1) {
          return days + '天'
        } else if (age < 2) {
          const remainingDays = Math.floor((currentDate - birthDate) / (24 * 60 * 60 * 1000))
          return '1年零' + remainingDays + '天'
        } else {
          return age + '岁'
        }
      } else {
        return ''
      }
    },

    deleteOperation() {
      let arr = []
      this.multipleSelection.forEach((ele) => {
        arr.push(ele.OPERATIONID)
      })
      this.$api
        .post(`/v1/webconsole/scientificResearchQueue/operation/delete/${this.queueId}`, [...arr])
        .then(() => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this.query_resultPost()
        })
    },
    searchClick(val) {
      const { href } = this.$router.resolve({
        path: `/follow/baseLine?${'moduleCode=' + this.moduleCode}${
          '&operationId=' + val.OPERATIONID
        }`
      })
      let url = `/v1/webconsole/study/operation/get/${val.OPERATIONID}`
      this.$api.get(url).then((res) => {
        localStorage.setItem('patientInfo', JSON.stringify(res.data.data))
        window.open(href, '_blank')
      })
    },
    addClick() {
      this.$refs.researchListDialog.Show()
    },
    editClick(val) {
      this.$refs.researchListDialog.Show(val)
    },

    closeInfo(id) {
      this.$confirm('是否删除该队列?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      })
        .then(() => {
          this._closeInfo(id)
        })
        .catch((err) => {
          console.log(err)
          this.$message({
            type: 'info',
            message: '已取消'
          })
        })
    },
    _closeInfo(id) {
      this.$api.delete(`/v1/webconsole/scientificResearchQueue/delete/${id}`).then(
        () => {
          this.$message({
            message: '删除成功',
            type: 'success'
          })
          this._dialogSave()
        },
        () => {
          this._dialogSave()
        }
      )
    },
    handleScientificList(val) {
      this.tableLoading = true
      this.addPatientsShow = false
      this.menusIds = []
      this.multipleSelection = []
      this.infoTitle = ''
      this.moduleCode = val.moduleCode
      this.moduleTitle = val.moduleTitle
      this.queueId = val.id
      this.queueName = val.queueName
      this.bigqueryInfo = null

      let data = {
        and: [],
        or: [],
        fields: []
      }

      this.$api
        .post(
          `/v1/webconsole/scientificResearchQueue/operation/fields/${this.queueId}?current=1&size=50&tag=${this.infoTitle}`,
          data
        )
        .then((res) => {
          if (res.data.status !== 200)
            this.$message.error('未查询到相关信息'), (this.tableData = [])
          if (res.data && res.data.data) {
            this.tableData = res.data.data.records.map((item) => {
              return { ...item, isSelected: false }
            })
            this.searchInfo.total = res.data.data.total
          } else {
            this.tableData = []
            this.searchInfo.total = 0
          }
          this.getFieldList()
          this.getBigquery()
          this.getAddPatientsTag()
          this.getDefaultFields()
          this.getDialogDefaultFields()
          this.getPatientList('clear')
          this.tableLoading = false
        })
    },

    flexColumnMinWidth(label, prop) {
      // 1.获取该列的所有数据
      const arr = this.tableData.map((x) => x[prop])
      arr.push(label)
      // 2.计算每列内容最大的宽度 + 表格的内间距（依据实际情况而定）
      return this.getMaxLength(arr) + 120 + 'px'
    },
    // 表格自适应宽度
    flexColumnWidth(label, prop) {
      // 1.获取该列的所有数据
      const arr = this.tableData.map((x) => x[prop])
      arr.push(label)
      // 2.计算每列内容最大的宽度 + 表格的内间距（依据实际情况而定）
      return this.getMaxLength(arr) + 60 + 'px'
    },
    getMaxLength(arr) {
      return arr.reduce((acc, item) => {
        if (item) {
          const calcLen = this.getTextWidth(item)
          if (acc < calcLen) {
            acc = calcLen
          }
        }
        return acc
      }, 0)
    },
    getTextWidth(str) {
      let width = 0
      const html = document.createElement('span')
      html.innerText = str
      html.className = 'getTextWidth'
      document.querySelector('body').appendChild(html)
      width = document.querySelector('.getTextWidth').offsetWidth
      if (width < 80) {
        // 设置最小宽度
        width = 80
      }
      if (width > 250) {
        // 设置最大宽度
        width = 250
      }
      document.querySelector('.getTextWidth').remove()
      return width
    },

    _dialogSavePatientsList(title, val) {
      let arr = []
      val.forEach((ele) => {
        arr.push(ele.OPERATIONID)
      })
      this.$api
        .post(`/v1/webconsole/scientificResearchQueue/operation/add/${this.queueId}?tag=${title}`, [
          ...arr
        ])
        .then((res) => {
          this.$message({
            message: res.data.msg,
            type: 'success'
          })
          let data = {
            and: [],
            or: [],
            fields: []
          }

          this.$api
            .post(
              `/v1/webconsole/scientificResearchQueue/operation/fields/${this.queueId}?current=1&size=50&tag=${this.infoTitle}`,
              data
            )
            .then((res) => {
              if (res.data.status !== 200)
                this.$message.error('未查询到相关信息'), (this.tableData = [])
              if (res.data && res.data.data) {
                this.tableData = res.data.data.records.map((item) => {
                  return { ...item, isSelected: false }
                })
                this.searchInfo.total = res.data.data.total
              } else {
                this.tableData = []
                this.searchInfo.total = 0
              }
              this.getBigquery()
              this.getAddPatientsTag()
              this.getPatientList('clear')
              this.addPatientsShow = false
            })
        })
    },
    getAddPatientsTag() {
      this.$api.post(`/v1/webconsole/scientificResearchQueue/tags/${this.queueId}`).then((res) => {
        if (res.data && res.data.data) {
          let arr = []
          res.data.data.forEach((ele) => {
            if (ele) {
              arr.push(ele)
            }
          })
          this.patientsTagList = arr
        }
      })
    },

    _dialogSaveFieldsList(val) {
      this.menusIds = val
      val = this.removeIdsWithChildren(this.dialogFieldsList, val)

      let fieldArr = []

      val.forEach((item) => {
        this.allFieldsList.forEach((element) => {
          if (item === element.id) {
            fieldArr.push({
              code: this.processNextFollowDate(element.data.field),
              field: element.data.field,
              group: element.data.group,
              title: element.title,
              datatype: element.data.datatype,
              id: element.id
            })
          }
        })
      })

      this.selectFieldsList = fieldArr
      if (fieldArr.length > 0) {
        this.defaultFieldsList = fieldArr
      }

      this.$forceUpdate()
    },

    removeIdsWithChildren(nodeList, val) {
      nodeList.forEach((item) => {
        if (item.child && item.child.length > 0) {
          // 删除当前 item 的 id
          val = val.filter((id) => id !== item.id)

          // 递归处理子节点
          val = this.removeIdsWithChildren(item.child, val)
        }
      })

      return val
    },

    _dialogSave() {
      this.getScientificResearchQueue()
    },
    getPatientList(val) {
      if (val && val === 'clear') {
        this.queryKeyword = ''
        this.changeValue = ''
        this.list = [
          {
            connect_options: [
              {
                value: 'and',
                label: 'and'
              },
              {
                value: 'or',
                label: 'or'
              }
            ],
            select_item: '',
            Retrieval_field_grouping: {
              nodeTitle: ''
            },
            defaultProps: {
              children: 'child',
              label: 'title'
            },
            Query_conditions_Visible: false,
            relationship: [
              {
                value: 'LIKE',
                label: '包含'
              },
              {
                value: 'NOTLIKE',
                label: '不包含'
              },
              {
                value: 'EQ',
                label: '等于'
              },
              {
                value: 'NOTEQ',
                label: '不等于'
              },
              {
                value: 'GE',
                label: '>='
              },
              {
                value: 'LE',
                label: '<='
              },
              {
                value: 'GT',
                label: '>'
              },
              {
                value: 'LT',
                label: '<'
              }
            ],
            field_values: {},
            nodedata: {}
          }
        ]
      }
      this.get_Retrieval_field_grouping()
    },
    addSearch() {
      this.query_condition.nodes = []
      let queryIsOk = true
      this.list.forEach((item) => {
        if (item.field_values.comboValue == undefined || item.field_values.comboValue == '') {
          this.$message.error('请将查询关系填写完整')
          this.tableLoading = false
          queryIsOk = false
        } else {
          this.query_condition.nodes.push({
            connect: item.connect_options.value == undefined ? 'and' : item.connect_options.value,
            datatype: item.nodedata.datatype,
            group: item.nodedata.group,
            field: item.nodedata.field,
            operator: item.relationship.value,
            value: item.field_values.comboValue,
            title: item.select_item
          })
        }
      })
      if (queryIsOk) {
        this.$refs.saveFieldsDialog.Show()
      }
    },
    saveSearch(title, type) {
      let data = {
        and: [],
        or: [],
        fields: []
      }
      if (this.query_condition.nodes.length) {
        this.query_condition.nodes.forEach((item) => {
          if (item.connect === 'and' || !item.connect) {
            data.and.push({
              group: item.group,
              field: item.field,
              datatype: item.datatype,
              operator: item.operator,
              value: item.value,
              title: item.title
            })
          } else {
            data.or.push({
              group: item.group,
              field: item.field,
              datatype: item.datatype,
              operator: item.operator,
              value: item.value,
              title: item.title
            })
          }
        })
      }
      if (this.selectFieldsList.length) {
        this.selectFieldsList.forEach((item) => {
          data.fields.push({
            group: item.group,
            field: item.field,
            title: item.title,
            id: item.id
          })
        })
      }

      this.$api
        .post(
          `/v1/webconsole/scientificResearchQueue/filter/save/${
            this.queueId
          }?title=${title}&isDefault=${Number(type)}`,
          data
        )
        .then(() => {
          this.menusIds = []
          this.getBigquery()
          this.query_resultPost()
          this.$message({
            message: '保存成功',
            type: 'success'
          })
        })
    },
    queryRetrieval() {
      this.tableLoading = true
      this.query_result()
    },

    getFieldList() {
      this.$api.post(`/v1/webconsole/search/tree/json/${this.moduleCode}`).then((res) => {
        let arr = []
        let fieldArr = []
        res.data.data.forEach((element) => {
          this.turnToTreeOfOneRootPlus(element.child)
          arr.push(element)
        })
        this.dialogFieldsList = arr
        const traverseNodes = (nodes) => {
          nodes.forEach((node) => {
            if (node.child && node.child.length > 0) {
              traverseNodes(node.child) // 递归处理子节点
            } else {
              fieldArr.push(node)
            }
          })
        }
        traverseNodes(this.dialogFieldsList)
        this.allFieldsList = fieldArr
      })
    },
    addFieldsList() {
      this.$refs.addFieldsListDialog.Show(this.dialogFieldsList, this.menusIds)
    },
    addPatientsList() {
      this.addPatientsShow = !this.addPatientsShow
      this.$refs.addPatients.Show()
    },

    ///查询相关函数
    // 新增一组组合条件
    addListItem() {
      let isOk = false
      this.list.forEach((item) => {
        if (item.relationship.value && item.field_values.comboValue) {
          isOk = false
        } else {
          this.$message.error('请将查询关系填写完整')
          isOk = true
        }
      })
      if (!isOk) {
        var newvalue = {
          connect_options: [
            {
              value: 'and',
              label: 'and'
            },
            {
              value: 'or',
              label: 'or'
            }
          ],
          select_item: '',
          Retrieval_field_grouping: {
            nodeTitle: ''
          },
          defaultProps: {
            children: 'child',
            label: 'title'
          },
          Query_conditions_Visible: false,
          relationship: [
            {
              value: 'LIKE',
              label: '包含'
            },
            {
              value: 'NOTLIKE',
              label: '不包含'
            },
            {
              value: 'EQ',
              label: '等于'
            },
            {
              value: 'NOTEQ',
              label: '不等于'
            },
            {
              value: 'GE',
              label: '>='
            },
            {
              value: 'LE',
              label: '<='
            },
            {
              value: 'GT',
              label: '>'
            },
            {
              value: 'LT',
              label: '<'
            }
          ],
          field_values: {},
          nodedata: {},
          tree_list: []
        }
        this.list.push(newvalue)
      }
    },
    //删除一组组合条件
    delListItem() {
      var index = this.list.length - 1
      this.list.splice(index, 1)
      var index2 = this.query_condition.nodes.length - 1
      this.query_condition.nodes.splice(index2, 1)
      this.query_resultPost()
    },
    //获得检索字段下拉框信息
    async get_Retrieval_field_grouping() {
      const { data: res } = await this.$http.get(
        SERVER_HOST + `/v1/webconsole/search/root/${this.moduleCode}`
      )
      if (res.status !== 200) return

      this.Retrieval_field_grouping = res.data
    },
    clearOptions(item) {
      item.nodedata = []
    },
    getOptions(item) {
      if (item.Retrieval_field_grouping.nodeTitle) {
        this.Query_conditions(item)
      } else {
        this.msg = this.$message({
          duration: 1000,
          type: 'error',
          message: '请先选择分组'
        })
      }
    },
    async changeConnectOptions(val, index) {
      if (val) {
        const { data: res } = await this.$http.get(
          SERVER_HOST + `/v1/webconsole/search/tree/${this.moduleCode}/${val}`
        )
        this.get_tree_data(res.data, index)
      }
    },
    //检索字段组合树
    Query_conditions(item) {
      item.select_item = ''
      item.Query_conditions_Visible = true
    },
    // 获得所选分组的所有树节点
    get_tree_data(val, index) {
      this.tree_list = this.turnToTreeOfOneRootPlus(val.child)
    },
    // 建树
    turnToTreeOfOneRootPlus(arr) {
      if (arr) {
        for (let obj of arr) {
          obj['title'] = obj['data'].title
          obj['datatype'] = obj['data'].datatype
          if (obj['child']) {
            this.turnToTreeOfOneRootPlus(obj['child'])
          }
        }
        return arr
      }
    },
    // 树选择节点函数
    select_items(data, item) {
      if (data.data.datatype !== 'Group') {
        item.Query_conditions_Visible = false
        item.nodedata = data.data
        item.select_item = data.title
        item.field_values.id = item.nodedata.id
        this.$set(item.field_values, 'comboValue', null)
        if (item.nodedata.selectItems) {
          this.$set(item.field_values, 'itemsList', item.nodedata.selectItems)
        }
      }
    },
    // 查询函数
    async query_result() {
      this.query_condition.nodes = []
      let queryIsOk = true
      this.list.forEach((item) => {
        if (item.field_values.comboValue == undefined || item.field_values.comboValue == '') {
          this.$message.error('请将查询关系填写完整')
          this.tableLoading = false
          queryIsOk = false
        } else {
          this.query_condition.nodes.push({
            connect: item.connect_options.value == undefined ? 'and' : item.connect_options.value,
            datatype: item.nodedata.datatype,
            group: item.nodedata.group,
            field: item.nodedata.field,
            operator: item.relationship.value,
            value: item.field_values.comboValue,
            title: item.select_item
          })
        }
      })
      if (queryIsOk) {
        this.query_resultPost()
      }
    },
    async query_resultPost() {
      let data = {
        and: [],
        or: [],
        fields: []
      }
      if (this.query_condition.nodes.length) {
        this.query_condition.nodes.forEach((item) => {
          if (item.connect === 'and' || !item.connect) {
            data.and.push({
              group: item.group,
              field: item.field,
              datatype: item.datatype,
              operator: item.operator,
              value: item.value,
              title: item.title
            })
          } else {
            data.or.push({
              group: item.group,
              field: item.field,
              datatype: item.datatype,
              operator: item.operator,
              value: item.value,
              title: item.title
            })
          }
        })
      }
      if (this.selectFieldsList.length) {
        this.selectFieldsList.forEach((item) => {
          data.fields.push({
            group: item.group,
            field: item.field,
            title: item.fieldName,
            datatype: item.datatype,
            id: item.id
          })
        })
      }

      this.$api
        .post(
          `/v1/webconsole/scientificResearchQueue/operation/fields/${this.queueId}?current=${this.searchInfo.pagenum}&size=${this.searchInfo.pagesize}&tag=${this.infoTitle}&keyword=${this.queryKeyword}`,
          data
        )
        .then(
          (res) => {
            if (res.data.status !== 200)
              this.$message.error('未查询到相关信息'), (this.tableData = [])
            if (res.data && res.data.data) {
              this.tableData = res.data.data.records.map((item) => {
                return { ...item, isSelected: false }
              })
              this.searchInfo.total = res.data.data.total
            } else {
              this.tableData = []
              this.searchInfo.total = 0
            }
          },
          (err) => {
            console.log(err)
          }
        )
        .finally(() => {
          this.tableLoading = false
        })
    },
    getScientificResearchQueue() {
      this.$api.get(`/v1/webconsole/scientificResearchQueue/queues`).then((res) => {
        if (res.data && res.data.status === 200) {
          this.allScientificList = res.data.data
          if (this.allScientificList.length > 0) {
            this.handleScientificList(this.allScientificList[0])
          }
        }
      })
    },
    changeQueryKeyword(val) {
      if (val != undefined) {
        // 判断值和变量,如果之和变量相等,则不做操作,否则执行
        if (this.changeValue == val) {
          return
        } else {
          // 储存我所改变的值
          this.changeValue = val
        }
        if (val === '') {
          this.query_resultPost()
        }
      }
    },
    onTableRowClassName({ rowIndex }) {
      if (rowIndex % 2 == 1) {
        return 'statistics-warning-row'
      } else {
        return ''
      }
    },
    headerStyle() {
      return 'tableHerderStyle'
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.searchInfo.pagesize = newSize
      this.query_resultPost()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.searchInfo.pagenum = newPage
      this.query_resultPost()
    }
  }
}
</script>
