<style scoped lang="less">
.el-input .el-select {
  width: 130px;
}
.add-form {
  /deep/ .el-dialog {
    background: #f8f8f8;

    .el-dialog__header {
      background: #2d507e;
      padding: 10px;
      .el-dialog__title {
        color: #fff;
      }
      .el-dialog__close {
        color: #fff;
      }
      .el-dialog__headerbtn {
        top: 0.9rem;
      }
    }

    .el-dialog__body {
      padding: 0;
      .dialog-btn-wrap {
        padding: 10px;
      }
      .dialog-label {
        padding: 20px;
        height: 64px;
        display: flex;
        align-items: center;
      }
    }
    .form-wrap {
      margin: 30px 0 20px;
      padding: 0 20px;
      .examinationItem {
        display: flex;
        align-items: center;
        .examinationItemTitle {
          width: 160px;
        }
        .examinationItemValue {
          height: 28px;
          line-height: 28px;
        }
      }
      .el-divider__text {
        background-color: #f8f8f8;
      }
      .el-row {
        margin-bottom: 15px;
      }
      .el-input {
        width: 250px;
      }
      .el-input__inner {
        border-color: #dcdfe6;
        height: 35px;
      }
      .el-tabs__header {
        position: fixed;
        z-index: 100;
        background: #fff;
      }
      .el-tabs__content {
        .container {
          margin-top: 80px;
        }
      }
    }
  }
}
.top-item-row {
  margin: 20px 30px;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.item-row {
  margin: 0.5rem 2rem;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
}
.item-row > span {
  margin: 0 15px 5px 0;
}
.content {
  flex: 1;
  overflow: auto;
  .label-title {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
  }
  .searchContent {
    margin: 10px 35px;
    .transition-box {
      min-height: 80px;
      .transition-box-content {
        margin-bottom: 40px;
      }
      .queryBox {
        margin-bottom: 20px;
        display: flex;
        align-items: center;
      }
    }
  }
  .tableContent {
    width: 100%;

    .patientNameContent {
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .patientIsDead {
      width: 20px;
      height: 20px;
      line-height: 15px;
      border-radius: 10px;
      border: 2px solid red;
      color: red;
    }
    /deep/ .el-table {
      border-left: none;
      border-right: none;
      border-bottom: none;
      .el-table__row {
        height: 56px;
        line-height: 56px;
        font-size: 14px;
      }
      .el-table__row.statistics-warning-row {
        background: #f8faff;
      }
      tr:nth-child(4n) {
        td.el-table__cell {
          background: #f8faff;
        }
      }
      td.el-table__cell {
        .cell {
          font-size: 14px;
        }
      }
    }
    /deep/ .el-table--enable-row-hover .el-table__body tr:hover > td {
      background-color: transparent;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar {
      width: 12px;
      height: 12px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-track {
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      border-radius: 10px;
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background: rgba(0, 0, 0, 0.1);
      -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
      box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    }
    /deep/ .el-table__body-wrapper::-webkit-scrollbar-thumb:window-inactive {
      background: #ccc;
    }
    /deep/ .tableHerderStyle {
      font-size: 14px;
      height: 40px;
      line-height: 40px;
    }
    .tableAnimationHeight {
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .el-pagination {
      text-align: center;
    }
    .topBtn {
      .topBtnItem {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0 12px;
        cursor: pointer;
        span {
          font-size: 14px;
        }
        .searchImg {
          width: 20px;
          height: 20px;
        }
        .listCImg {
          width: 20px;
          height: 20px;
        }
      }
    }
  }
}

.button-panel {
  width: 100%;
  padding: 10px 0;
  display: flex;
  box-shadow: 0 2px 2px #eee;
}
/deep/ .dialog-content-content {
  max-height: 50rem;
}
</style>

<template>
  <div>
    <div class="button-panel">
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="Save"
      >
        添加
      </el-button>
      <el-button
        type="success"
        class="common-button commonBtn"
        icon="el-icon-finished"
        v-debounce="queryRetrieval"
      >
        搜索
      </el-button>
    </div>
    <div class="content">
      <div class="searchContent">
        <div class="transition-box">
          <div class="transition-box-content" v-for="(item, index) in list" :key="index">
            <!-- 连接关系 -->
            <div class="queryBox">
              <span style="margin-right: 20px">连接关系</span>
              <el-select
                v-model="item.connect_options.value"
                style="margin-right: 20px"
                clearable
                v-if="index >= 1"
              >
                <el-option
                  v-for="item1 in item.connect_options"
                  :key="item1.value"
                  :label="item1.label"
                  :value="item1.value"
                ></el-option>
              </el-select>
              <el-input
                v-model="item.select_item"
                class="input-with-select"
                style="width: 40%"
                clearable
                @clear="clearOptions(item)"
                @focus="getOptions(item)"
                :disabled="moduleCode === ''"
              >
                <el-select
                  v-model="item.Retrieval_field_grouping.nodeTitle"
                  slot="prepend"
                  placeholder="请选择分组"
                  @change="changeConnectOptions(item.Retrieval_field_grouping.nodeTitle, index)"
                  :disabled="moduleCode === ''"
                >
                  <el-option
                    v-for="item1 in Retrieval_field_grouping"
                    :key="item1.id"
                    :label="item1.title"
                    :value="item1.id"
                  ></el-option>
                </el-select>
              </el-input>
            </div>

            <!-- 运算关系 -->
            <div style="margin-bottom: 20px; display: flex; align-items: center">
              <span style="margin-right: 20px">运算关系</span>
              <el-select v-model="item.relationship.value" style="margin-right: 20px" clearable>
                <el-option
                  v-for="item1 in item.relationship"
                  :key="item1.value"
                  :label="item1.label"
                  :value="item1.value"
                ></el-option>
              </el-select>
              <!-- 根据所选树节点类型去调用不同的组件 -->
              <!-- 下拉框-combodata-->
              <el-select
                v-model="item.field_values.comboValue"
                style="margin-left: 20px; width: 20%"
                clearable
                v-if="item.nodedata.selectItems"
              >
                <el-option
                  v-for="(item1, idx) in item.field_values.itemsList"
                  :key="item1.id || idx"
                  :label="item1.title"
                  :value="item1.title"
                ></el-option>
              </el-select>
              <!-- dateSelect -->
              <el-date-picker
                v-model="item.field_values.comboValue"
                type="datetime"
                align="center"
                placeholder="选择日期"
                v-else-if="item.nodedata.datatype === 'date'"
                value-format="yyyy-MM-dd HH:mm:ss"
                format="yyyy-MM-dd HH:mm"
              ></el-date-picker>
              <el-input
                clearable
                v-model="item.field_values.comboValue"
                v-else-if="item.nodedata.datatype === 'string'"
                style="margin-left: 20px; width: 20%"
              ></el-input>
              <el-input
                clearable
                type="number"
                v-else-if="item.nodedata.datatype === 'number'"
                v-model="item.field_values.comboValue"
                style="margin-left: 20px; width: 20%"
              ></el-input>

              <el-button
                v-if="index === list.length - 1 || list.length === 1"
                size="mini"
                type="primary"
                class="commonBtn"
                style="margin-left: 20px"
                @click="addListItem"
              >
                <i class="el-icon-plus" />
                新增
              </el-button>
              <el-button
                @click="delListItem"
                size="mini"
                type="danger"
                class="commonBtn"
                style="margin-left: 30px"
                v-if="index > 0"
              >
                删除
              </el-button>
            </div>

            <!-- 检索字段搜查的树 -->
            <el-dialog
              title="查询条件选择"
              :visible.sync="item.Query_conditions_Visible"
              append-to-body
              width="50%"
            >
              <el-tree
                :data="tree_list"
                :props="item.defaultProps"
                default-expand-all
                @current-change="
                  (val) => {
                    select_items(val, item)
                  }
                "
              ></el-tree>
            </el-dialog>
          </div>
        </div>
      </div>
      <div class="tableContent">
        <el-table
          v-loading="tableLoading"
          :data="tableData"
          style="width: 100%"
          border
          :row-class-name="onTableRowClassName"
          :height="tableHeight"
          :header-cell-class-name="headerStyle"
          @selection-change="handleSelectionChange"
        >
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column
            :label="item.title"
            :property="item.code"
            v-for="item in dialogDefaultFieldsList"
            :key="item.id"
            align="center"
          >
            <template slot-scope="scope">
              <span>{{ scope.row[scope.column.property] }}</span>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="searchInfo.pagenum"
          :page-sizes="[15, 30, 50]"
          :page-size="searchInfo.pagesize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="searchInfo.total"
        ></el-pagination>
      </div>
    </div>
    <el-dialog class="add-form" title="添加患者" :visible.sync="innerVisible" width="30%">
      <div class="dialog-btn-wrap">
        <el-button @click="_save" type="primary" size="mini" class="commonBtn">确认</el-button>
        <el-button @click="innerVisible = false" type="primary" size="mini" class="commonBtn">
          取消
        </el-button>
      </div>
      <div class="dialog-label">
        <span style="margin-right: 10px">分组</span>
        <el-radio-group size="mini" v-model="queryTitle">
          <el-radio
            v-for="(item, index) in options"
            :key="index"
            :label="item"
            @click.native.prevent="handlePatientsTag(item)"
            border
          >
            {{ item }}
          </el-radio>
        </el-radio-group>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { SERVER_HOST } from '@/utils/globalVariable'

export default {
  name: 'AddPatients',
  components: {},
  props: {
    moduleCode: {
      type: String,
      default: () => ''
    },
    dialogDefaultFieldsList: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      tableLoading: false,
      innerVisible: false,
      tableHeight: window.innerHeight - 310, //表格动态高度
      screenHeight: window.innerHeight, //内容区域高度
      queryTitle: '',
      tableData: [],
      multipleSelection: [],
      searchInfo: {
        pagenum: 1,
        pagesize: 50,
        total: 0
      },
      //字段分组下拉框
      Retrieval_field_grouping: [],
      query_condition: {
        nodes: []
      },

      list: [
        {
          connect_options: [
            {
              value: 'and',
              label: 'and'
            },
            {
              value: 'or',
              label: 'or'
            }
          ],
          select_item: '',
          Retrieval_field_grouping: {
            nodeTitle: ''
          },
          defaultProps: {
            children: 'child',
            label: 'title'
          },
          Query_conditions_Visible: false,
          relationship: [
            {
              value: 'LIKE',
              label: '包含'
            },
            {
              value: 'NOTLIKE',
              label: '不包含'
            },
            {
              value: 'EQ',
              label: '等于'
            },
            {
              value: 'NOTEQ',
              label: '不等于'
            },
            {
              value: 'GE',
              label: '>='
            },
            {
              value: 'LE',
              label: '<='
            },
            {
              value: 'GT',
              label: '>'
            },
            {
              value: 'LT',
              label: '<'
            }
          ],
          field_values: {},
          nodedata: {}
        }
      ],
      options: ['对照组', '参照组'],
      tree_list: []
    }
  },
  mounted() {
    window.onresize = () => {
      return () => {
        // window.innerHeight:浏览器的可用高度
        window.screenHeight = window.innerHeight
        this.screenHeight = window.screenHeight
      }
    }
  },
  watch: {
    // 监听screenHeight从而改变table的高度
    screenHeight(val) {
      this.screenHeight = val
      this.tableHeight = this.screenHeight - 29
    }
  },
  methods: {
    handlePatientsTag(title) {
      if (this.queryTitle === title) {
        this.queryTitle = ''
      } else {
        this.queryTitle = title
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    getOperation() {
      let data = {
        and: [],
        or: []
      }
      this.list = [
        {
          connect_options: [
            {
              value: 'and',
              label: 'and'
            },
            {
              value: 'or',
              label: 'or'
            }
          ],
          select_item: '',
          Retrieval_field_grouping: {
            nodeTitle: ''
          },
          defaultProps: {
            children: 'child',
            label: 'title'
          },
          Query_conditions_Visible: false,
          relationship: [
            {
              value: 'LIKE',
              label: '包含'
            },
            {
              value: 'NOTLIKE',
              label: '不包含'
            },
            {
              value: 'EQ',
              label: '等于'
            },
            {
              value: 'NOTEQ',
              label: '不等于'
            },
            {
              value: 'GE',
              label: '>='
            },
            {
              value: 'LE',
              label: '<='
            },
            {
              value: 'GT',
              label: '>'
            },
            {
              value: 'LT',
              label: '<'
            }
          ],
          field_values: {},
          nodedata: {}
        }
      ]
      this.$api
        .post(`/v1/webconsole/search/query/custom/${this.moduleCode}?current=1&size=50`, data)
        .then((res) => {
          if (res.data.status !== 200)
            this.$message.error('未查询到相关信息'), (this.tableData = [])
          if (res.data && res.data.data) {
            this.tableData = res.data.data.records
            this.searchInfo.total = res.data.data.total
          } else {
            this.tableData = []
            this.searchInfo.total = 0
          }
          this.tableLoading = false
        })
    },
    // 新增一组组合条件
    addListItem() {
      let isOk = false
      this.list.forEach((item) => {
        if (item.relationship.value && item.field_values.comboValue) {
          isOk = false
        } else {
          this.$message.error('请将查询关系填写完整')
          isOk = true
        }
      })
      if (!isOk) {
        var newvalue = {
          connect_options: [
            {
              value: 'and',
              label: 'and'
            },
            {
              value: 'or',
              label: 'or'
            }
          ],
          select_item: '',
          Retrieval_field_grouping: {
            nodeTitle: ''
          },
          defaultProps: {
            children: 'child',
            label: 'title'
          },
          Query_conditions_Visible: false,
          relationship: [
            {
              value: 'LIKE',
              label: '包含'
            },
            {
              value: 'NOTLIKE',
              label: '不包含'
            },
            {
              value: 'EQ',
              label: '等于'
            },
            {
              value: 'NOTEQ',
              label: '不等于'
            },
            {
              value: 'GE',
              label: '>='
            },
            {
              value: 'LE',
              label: '<='
            },
            {
              value: 'GT',
              label: '>'
            },
            {
              value: 'LT',
              label: '<'
            }
          ],
          field_values: {},
          nodedata: {},
          tree_list: []
        }
        this.list.push(newvalue)
      }
    },
    //删除一组组合条件
    delListItem() {
      var index = this.list.length - 1
      this.list.splice(index, 1)
      var index2 = this.query_condition.nodes.length - 1
      this.query_condition.nodes.splice(index2, 1)
      this.query_resultPost()
    },
    //获得检索字段下拉框信息
    async get_Retrieval_field_grouping() {
      const { data: res } = await this.$http.get(
        SERVER_HOST + `/v1/webconsole/search/root/${this.moduleCode}`
      )
      if (res.status !== 200) return

      this.Retrieval_field_grouping = res.data
    },
    clearOptions(item) {
      item.nodedata = []
    },
    getOptions(item) {
      if (item.Retrieval_field_grouping.nodeTitle) {
        this.Query_conditions(item)
      } else {
        this.msg = this.$message({
          duration: 1000,
          type: 'error',
          message: '请先选择分组'
        })
      }
    },
    async changeConnectOptions(val, index) {
      if (val) {
        const { data: res } = await this.$http.get(
          SERVER_HOST + `/v1/webconsole/search/tree/${this.moduleCode}/${val}`
        )
        this.get_tree_data(res.data, index)
      }
    },
    //检索字段组合树
    Query_conditions(item) {
      item.select_item = ''
      item.Query_conditions_Visible = true
    },
    // 获得所选分组的所有树节点
    get_tree_data(val, index) {
      this.tree_list = this.turnToTreeOfOneRootPlus(val.child)
    },
    // 建树
    turnToTreeOfOneRootPlus(arr) {
      if (arr) {
        for (let obj of arr) {
          obj['title'] = obj['data'].title
          obj['datatype'] = obj['data'].datatype
          if (obj['child']) {
            this.turnToTreeOfOneRootPlus(obj['child'])
          }
        }
        return arr
      }
    },
    //树选择节点函数
    select_items(data, item) {
      if (data.data.datatype !== 'Group') {
        item.Query_conditions_Visible = false
        item.nodedata = data.data
        item.select_item = data.title
        item.field_values.id = item.nodedata.id
        this.$set(item.field_values, 'comboValue', null)
        if (item.nodedata.selectItems) {
          this.$set(item.field_values, 'itemsList', item.nodedata.selectItems)
        }
      }
    },
    queryRetrieval() {
      this.tableLoading = true
      this.query_result()
    },
    //查询函数
    async query_result() {
      this.query_condition.nodes = []
      let queryIsOk = true
      this.list.forEach((item) => {
        if (item.field_values.comboValue == undefined || item.field_values.comboValue == '') {
          this.$message.error('请将查询关系填写完整')
          this.tableLoading = false
          queryIsOk = false
        } else {
          this.query_condition.nodes.push({
            connect: item.connect_options.value == undefined ? 'and' : item.connect_options.value,
            datatype: item.nodedata.datatype,
            group: item.nodedata.group,
            field: item.nodedata.field,
            operator: item.relationship.value,
            value: item.field_values.comboValue,
            title: item.select_item
          })
        }
      })
      if (queryIsOk) {
        this.query_resultPost()
      }
    },
    async query_resultPost() {
      let data = {
        and: [],
        or: []
      }
      if (this.query_condition.nodes.length) {
        this.query_condition.nodes.forEach((item) => {
          if (item.connect === 'and' || !item.connect) {
            data.and.push({
              group: item.group,
              field: item.field,
              datatype: item.datatype,
              operator: item.operator,
              value: item.value,
              title: item.title
            })
          } else {
            data.or.push({
              group: item.group,
              field: item.field,
              datatype: item.datatype,
              operator: item.operator,
              value: item.value,
              title: item.title
            })
          }
        })
      }

      this.$api
        .post(
          `/v1/webconsole/search/query/custom/${this.moduleCode}?current=${this.searchInfo.pagenum}&size=${this.searchInfo.pagesize}`,
          data
        )
        .then(
          (res) => {
            if (res.data.status !== 200)
              this.$message.error('未查询到相关信息'), (this.tableData = [])
            if (res.data && res.data.data) {
              this.tableData = res.data.data.records
              this.searchInfo.total = res.data.data.total
            } else {
              this.tableData = []
              this.searchInfo.total = 0
            }
          },
          (err) => {
            console.log(err)
          }
        )
        .finally(() => {
          this.tableLoading = false
        })
    },
    onTableRowClassName({ rowIndex }) {
      if (rowIndex % 2 == 1) {
        return 'statistics-warning-row'
      } else {
        return ''
      }
    },
    headerStyle() {
      return 'tableHerderStyle'
    },
    // 监听 pagesize 改变的事件
    handleSizeChange(newSize) {
      this.searchInfo.pagesize = newSize
      this.query_resultPost()
    },
    // 监听 页码值 改变的事件
    handleCurrentChange(newPage) {
      this.searchInfo.pagenum = newPage
      this.query_resultPost()
    },
    Show() {
      this.get_Retrieval_field_grouping()
      this.getOperation()
      this.queryTitle = ''
      this.multipleSelection = []
    },
    Save() {
      if (this.multipleSelection.length === 0) {
        return this.$message.warning('请选择手术')
      }
      if (this.innerVisible === false) {
        this.innerVisible = true
      }
    },
    _save() {
      if (!this.queryTitle) {
        return this.$message.warning('请选择分组')
      }
      this.innerVisible = false

      this.$emit('save', this.queryTitle, this.multipleSelection)
    }
  }
}
</script>
